import { Currency, ETHER, Token } from '@pancakeswap-libs/sdk'
import React, { useMemo } from 'react'
import styled from 'styled-components'
// import AxiosUtil from 'utils/AxiosUtil'
// import { Base64 } from 'js-base64';
import useHttpLocations from '../../hooks/useHttpLocations'
import { WrappedTokenInfo } from '../../state/lists/hooks'
import Logo from '../Logo'
import CoinLogo from '../pancake/CoinLogo'
import bnbLogo from '../../assets/images/bnb.png'

const getTokenLogoURL = (address: string) => `https://assets-cdn.trustwallet.com/blockchains/smartchain/assets/${address}/logo.png`
  

const StyledBnbLogo = styled.img<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
  border-radius: 24px;
`

const StyledLogo = styled(Logo)<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
`

export default function CurrencyLogo({
  currency,
  size = '24px',
  style,
}: {
  currency?: Currency
  size?: string
  style?: React.CSSProperties
}) {
  
  const uriLocations = useHttpLocations(currency instanceof WrappedTokenInfo ? currency.logoURI : undefined)

  const srcs: string[] = useMemo(() => {
    // const result: ApiResponse<any> = await AxiosUtil.request({
    //   url: '/api/v1/infoapi/tokenaddrfind',
    //   method: 'POST',
    //   data: {
    //     address
    //   }
    // })
    // console.log(currency);
    
    if (currency === ETHER) return []

    if (currency instanceof Token) {
      if (currency instanceof WrappedTokenInfo) {
        return [...uriLocations, getTokenLogoURL(currency.address)]
      }
      // console.log(currency.address, 'token');
      // if (currency.address) {
      //   AxiosUtil.request({
      //     url: '/api/v1/infoapi/tokenaddrfind',
      //     method: 'POST',
      //     data: {
      //       address: currency.address
      //     }
      //   }).then(res => {
      //     const result:any = res
      //     if (result.data.imgurl) {
      //       console.log(Base64.decode(result.data.imgurl), 'currency.address');
      //       return [Base64.decode(result.data.imgurl)]
      //     }
      //     return [getTokenLogoURL(currency.address)]
      //   })
      // }
      return [getTokenLogoURL(currency.address)]
    }
    return []
  }, [currency, uriLocations])

  if (currency === ETHER) {
    return <StyledBnbLogo src={bnbLogo}size={size} style={style} />
  }

  // console.log(srcs);
  

  return (currency as any)?.symbol ? (
    <CoinLogo size={size} srcs={srcs} alt={`${currency?.symbol ?? 'token'} logo`} style={style} />
  ) : (
    <StyledLogo size={size} srcs={srcs} alt={`${currency?.symbol ?? 'token'} logo`} style={style} />
  )
}
