import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
// import { RouteComponentProps } from 'react-router-dom'
import AxiosUtil, {ApiResponse} from 'utils/AxiosUtil'
import { ImageUploader, Toast, Button } from 'antd-mobile'
import { ImageUploadItem } from 'antd-mobile/es/components/image-uploader'
import { useTranslation } from 'react-i18next'
// import qs from 'qs'
// import type { ToastHandler } from 'antd-mobile/es/components/toast'
import { isAddress, getSigner } from 'utils'
import { ethers } from 'ethers'
import { Base64 } from 'js-base64';
import Container from '../../components/Container'
import { useActiveWeb3React } from '../../hooks'

const AddTokenBody = styled.div`
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0px 2px 0px 0px #E7E3EB;
  border-radius: 24px;
  border: 1px solid #E7E4EC;
  box-sizing: border-box;
`
const AddTokenFormBox = styled.div`
  padding: 24px 24px 6px;
  border-bottom: 1px solid #E9E4F3;
  overflow: hidden;
`
const AddTokenItemBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  input {
    flex: 1;
    min-width: 0;
    flex-shrink: 0;
    border: none;
    outline: none;
    text-align: right;
    font-size: 15px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #2B106B;
    line-height: 21px;
    &::-webkit-input-placeholder {
      color: #CBBCEE;
    }
  }
`
const AddTokenLabel = styled.div`
  width: 50%;
  flex-shrink: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 15px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #2B106B;
  line-height: 21px;
`
const AddTokenBtn = styled.div`
  display: block;
  width: 85.67%;
  margin: 30px auto 34px;
  button {
    height: 44px;
    background: #3EC8D4;
    border-radius: 12px;
    font-size: 16px;
    font-family: Kanit, sans-serif;
    font-weight: bold;
    color: #FFFFFF;
    border: none;
    outline: none;
  }
`
// interface Tokendata {
//   token: string;
//   num: string;
// }

// type ApiResponses = {
//   updated_at: string
//   data: {
//     [key: string]: {
//       name: string
//       symbol: string
//       price: string
//       price_BNB: string
//     }
//   }
// }
async function sendOssData(api: string, param: any) {
  try {
    const response = await fetch(api, {
      method: 'post',
      headers: {},
      body: param
    })
    await response.json()
  } catch (error) {
    console.error('Unable to fetch price data:', error)
  }
}

export default function LiquidityDetail() {
  const [fileList, setFileList] = useState<ImageUploadItem[]>([])
  const [tokenname, setTokenname] = useState<string>('')
  const [hyaddress, setHyaddress] = useState<string>('')
  const [content, setContent] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const maxCount = 1
  const { account, library } = useActiveWeb3React()
  const { t } = useTranslation()
  
  async function mockUpload(file: File) {
    await AxiosUtil.request({ url: '/api/v1/publicapi/getnonce', method: 'POST', data: { address: account }})
    const ossResult: ApiResponse<any>  = await AxiosUtil.request({ url: '/api/v1/infoapi/ossindex', method: 'POST', data: { address: account, filename: file.name }})
    const ossData = ossResult.data.postdata.oss_data
    const formData = new FormData()
    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const key in ossData) {
      formData.append(key, ossData[key])
    }
    formData.append('file', file, file.name)
    await sendOssData(ossData.host, formData)
    return {
      url: `${ossData.host}/${ossData.key}`,
    }
  }
  // async function mockUploadFail() {
  //   throw new Error('Fail to upload')
  // }
  function beforeUpload(file: File) {
    if (file.size > 1024 * 1024) {
      Toast.show({content: t('请选择小于 1M 的图片')})
      // Toast.show({
      //   icon: 'loading',
      //   duration: 0,
      //   maskClickable: false,
      // })
      return null
    }
    return file
  }
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  function changeTokenName(item) {
    setTokenname(item.target.value)
  }
  function changeHyaddress(item) {
    setHyaddress(item.target.value)
  }
  function changeContent(item) {
    setContent(item.target.value)
  }
  async function doSubmit() {
    // handler.current = Toast.show({
    //   icon: 'loading',
    //   duration: 0,
    //   maskClickable: false,
    // })
    setLoading(true)
    try {
      if (typeof(library) === 'object' && typeof(account) === 'string') {
        const resnonce: ApiResponse<any> = await AxiosUtil.request({ url: '/api/v1/publicapi/getnonce', method: 'POST', data: { address: account }})
        const nonce: string = resnonce?.data?.nonce
        const signer = getSigner(library, account)
        const signature = await signer.signMessage(ethers.utils.arrayify(ethers.utils.toUtf8Bytes(nonce)))       
        const args = {
          address: account,
          tokenname,
          hyaddress,
          imgurl: Base64.encode(fileList[0].url),
          content: Base64.encode(content),
          signature
        }
        await AxiosUtil.request({
          url: '/api/v1/publicapi/applytokensave',
          method: 'POST',
          data: args
        })
        setFileList([])
        setTokenname('')
        setHyaddress('')
        setContent('')
        Toast.show({content: t('申请提交成功')})
      }
      setLoading(false)
      // handler.current?.close()
    }
    catch (err) {
      setLoading(false)
      // handler.current?.close()
    }
  }
  // eslint-disable-next-line consistent-return
  function checkSubmit(): any {
    if (!fileList.length || !tokenname || !hyaddress || !content) {
      return Toast.show({content: t('请完善代币信息')})
    }
    if (!isAddress(hyaddress)) {
      return Toast.show({content: t('请正确输入代币地址')})
    }
    doSubmit()
  }
  return (
    <Container>
      <AddTokenBody>
        <AddTokenFormBox>
          <AddTokenItemBox>
            <AddTokenLabel>{t('logo上传')}</AddTokenLabel>
            <ImageUploader
              style={{ '--cell-size': '24px' }}
              value={fileList}
              onChange={setFileList}
              upload={mockUpload}
              maxCount={1}
              showUpload={fileList.length < maxCount}
              beforeUpload={beforeUpload}
            >
              <div
                style={{
                  width: 24,
                  height: 24,
                  borderRadius: 12,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <img src="./images/uploadToken.png" alt="" />
              </div>
            </ImageUploader>
          </AddTokenItemBox>
          <AddTokenItemBox>
            <AddTokenLabel>{t('代币名称')}</AddTokenLabel>
            <input placeholder={t('请输入名称')} onChange={changeTokenName} value={tokenname} />
          </AddTokenItemBox>
          <AddTokenItemBox>
            <AddTokenLabel>{t('合约地址')}</AddTokenLabel>
            <input placeholder={t('请输入地址')} onChange={changeHyaddress} value={hyaddress} />
          </AddTokenItemBox>
          <AddTokenItemBox>
            <AddTokenLabel>{t('项目介绍')}</AddTokenLabel>
            <input placeholder={t('请输入项目介绍')} onChange={changeContent} value={content} />
          </AddTokenItemBox>
        </AddTokenFormBox>
        <AddTokenBtn onClick={checkSubmit}>
          <Button block loading={loading} disabled={loading} loadingText={t('正在提交')}>{t('提交')}</Button>
        </AddTokenBtn>
      </AddTokenBody>
    </Container>
  )
}

// function e(reason: any): PromiseLike<never> {
//   throw new Error('Function not implemented.');
// }
