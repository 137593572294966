import React, { Suspense, useEffect, useState, useRef } from 'react'
import BigNumber from "bignumber.js";
import { HashRouter, Route, Switch } from 'react-router-dom'
import styled from 'styled-components'
import { LangType } from '@pancakeswap-libs/uikit'
import { Popup, Button, Toast } from 'antd-mobile'
import type { ToastHandler } from 'antd-mobile/es/components/toast'
import { ethers } from 'ethers'
import useAuth from 'hooks/useAuth'
import AxiosUtil, {ApiResponse} from 'utils/AxiosUtil'
import { getSigner, getRechargeContract, errorformat } from 'utils'
import copy from 'copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import Popups from '../components/Popups'
import Web3ReactManager from '../components/Web3ReactManager'
import { useToken } from '../hooks/Tokens'
import { RedirectDuplicateTokenIds, RedirectOldAddLiquidityPathStructure } from './AddLiquidity/redirects'
import { RedirectOldRemoveLiquidityPathStructure } from './RemoveLiquidity/redirects'
import AddLiquidity from './AddLiquidity'
import LiquidityDetail from './LiquidityDetail'
import FooterContainer from "../components/Footer";
import AddToken from './AddToken'
import Pool from './Pool'
import PoolFinder from './PoolFinder'
import RemoveLiquidity from './RemoveLiquidity'
import Subscribe from './Subscribe';
import Swap from './Swap'
import Team from './Team'
import { RedirectPathToSwapOnly } from './Swap/redirects'
import { EN, allLanguages } from '../constants/localisation/languageCodes'
import { LanguageContext } from '../hooks/LanguageContext'
import { TranslationsContext } from '../hooks/TranslationsContext'
import { useActiveWeb3React } from '../hooks'

import Menu from '../components/Menu'
// import useGetDocumentTitlePrice from '../hooks/useGetDocumentTitlePrice'

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  overflow-x: hidden;
  background: linear-gradient(180deg, #E7FDFF 0%, #EEF4FF 100%);
`
const BodyWrapper = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 1;
  //margin-bottom: 64px;
  ${({ theme }) => theme.mediaQueries.lg} {
    margin-bottom: 0;
  }
`
const WalletBox = styled.div`
  .header {
    height: 80px;
    border-bottom: 1px solid #E9E4F3;
    padding: 0 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: url("./images/walletBg.png") left top/100% 100% no-repeat;
    .title {
      font-size: 20px;
      font-family: Kanit, sans-serif;
      font-weight: bold;
      color: #2B106B;
    }
    .close {
      width: 18px;
      height: 18px;
      background: url("./images/popclose.png") left top/100% 100% no-repeat;
      padding: 0;
      border: none;
    }
  }
  .content {
    min-height: 30vh;
    max-height: 80vh;
    overflow: hidden;
    word-break: break-all;
    overflow-y: scroll;
    padding: 0 24px 24px;
    .subtitle {
      font-size: 12px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #8660DC;
      line-height: 17px;
      margin: 24px 0 10px;
    }
    .address-box {
      height: 50px;
      background: #EEEAF4;
      border-radius: 8px 8px 8px 8px;
      border: 1px solid rgba(174,169,202,0.3);
      display: flex;
      align-items: center;
      padding: 0 12px;
      margin-bottom: 24px;
      .address-copy {
        width: 16px;
        height: 16px;
        flex-shrink: 0;
        background: url("./images/addressCopy@2x.png") left top/100% 100% no-repeat;
        padding: 0;
        border: none;
      }
      span {
        display: block;
        font-size: 16px;
        font-family: Kanit, sans-serif;
        /* font-weight: bold; */
        color: #2B106B;
        flex: 1;
        min-width: 0;
        flex-shrink: 0;
        overflow: hidden;
        white-space: nowrap;
        margin-right: 13px;
      }
    }
    .chain-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .left {
        width: 225px;
        background: #EEEAF4;
        border-radius: 8px 8px 8px 8px;
        border: 1px solid rgba(174,169,202,0.3);
        padding: 14px 16px 14px 12px;
        display: flex;
        align-items: center;
        flex-shrink: 0;
        img {
          display: block;
          flex-shrink: 0;
          width: 24px;
          height: 24px;
          margin-right: 12px;
        }
        span {
          flex: 1;
          font-size: 16px;
          font-family: Kanit, sans-serif;
          /* font-weight: bold; */
          color: #7B72A9;
          line-height: 19px;
        }
      }
      .right {
        font-size: 16px;
        font-family: Kanit, sans-serif;
        /* font-weight: bold; */
        color: #3EC8D4;
        span {
          padding-right: 6px;
        }
        img {
          width: 16px;
        }
      }
    }
    .balance {
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .title {
        font-size: 14px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #7B72A9;
      }
      .num {
        font-size: 14px;
        font-family: Kanit, sans-serif;
        /* font-weight: bold; */
        color: #2B106B;
      }
    }
    .fenhong {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 16px 0;
      .title {
        font-size: 14px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #7B72A9;
        line-height: 16px;
      }
      .nums {
        display: flex;
        align-items: center;
        span {
          font-size: 14px;
          font-family: Kanit, sans-serif;
          /* font-weight: bold; */
          color: #2B106B;
          line-height: 16px;
          padding-right: 8px;
        }
        .btn {
          line-height: 22px;
          background: #3EC8D4;
          border-radius: 11px;
          font-size: 11px;
          font-family: Kanit, sans-serif;
          font-weight: bold;
          color: #FFFFFF;
          padding: 0 12px;
          span {
            color: #FFFFFF;
            font-size: 11px;
            padding: 0;
          }
        }
      }
    }
    .order-btn {
      margin-top: 30px;
      height: 50px;
      background: #3EC8D4;
      border-radius: 16px 16px 16px 16px;
      font-size: 16px;
      font-family: Kanit, sans-serif;
      box-shadow: 0px 1px 0px 0px #44ACB9;
      font-weight: bold;
      color: #FFFFFF;
      border: none;
      outline: none;
    }
  }
`
const CollectBtn = styled.div`
  line-height: 22px;
  background: #3EC8D4;
  border-radius: 11px;
  font-size: 11px;
  font-family: Kanit, sans-serif;
  font-weight: bold;
  color: #FFFFFF;
  padding: 0 12px;
`

// const Marginer = styled.div`
//   margin-top: 5rem;
// `

const CACHE_KEY = 'pancakeSwapLanguage'

export default function App() {
  const [selectedLanguage, setSelectedLanguage] = useState<any>(undefined)
  const [translatedLanguage, setTranslatedLanguage] = useState<any>(undefined)
  const [translations, setTranslations] = useState<Array<any>>([])
  const [walletPop, setWalletPop] = useState<boolean>(false)
  const [address, setAddress] = useState<string>('')
  const [ptbnum, setPtbnum] = useState<string>('')
  const [usdtnum, setUsdtnum] = useState<string>('')
  const [lvname, setLvname] = useState<string>('')
  const [banance, setBalance] = useState<any>('0')

  const currencyIdA:string = process.env.REACT_APP_PING_ADDRESS || ''
  const currencyIdB:string = process.env.REACT_APP_USDT_ADDRESS || ''
  // const [currencyA, currencyB] = [useCurrency(currencyIdA) ?? undefined, useCurrency(currencyIdB) ?? undefined]
  const currencyTokenA = useToken(currencyIdA)
  const currencyTokenB = useToken(currencyIdB)
  const { t } = useTranslation()

  const handler = useRef<ToastHandler>()

  const { logout } = useAuth()
  const getStoredLang = (storedLangCode: string) => {
    return allLanguages.filter((language) => {
      return language.code === storedLangCode
    })[0]
    setTranslations(allLanguages)
  }
  const { account, library } = useActiveWeb3React()
  
  useEffect(() => {
    if (account && typeof account === 'string') {
      library?.getBalance(account).then(res => {
        const etherString = ethers.utils.formatEther(res);
        setBalance(etherString)
      })
    }
  }, [library, account])

  useEffect(() => {
    const storedLangCode = localStorage.getItem(CACHE_KEY)
    if (storedLangCode) {
      const storedLang = getStoredLang(storedLangCode)
      setSelectedLanguage(storedLang)
    } else {
      setSelectedLanguage(EN)
    }
    window.addEventListener("walletClick", () => {
      setWalletPop(true)
    })
  }, [])

  useEffect(() => {
    if (account) {
      setAddress(account)
    }
  }, [account])

  useEffect(() => {
    if (walletPop) {
      AxiosUtil.request({
        url: '/api/v1/infoapi/walletindex',
        method: 'POST',
        data: { address }
      }).then(res => {
        const result:any = res
        setLvname(result.data.lvname)
        setPtbnum(result.data.ptbnum)
        setUsdtnum(result.data.usdtnum)
      })
    }
  }, [walletPop, address])

  useEffect(() => {
    if (address) {
      AxiosUtil.request({ url: '/api/v1/publicapi/getnonce', method: 'POST', data: { address }})
    }
  }, [address])
  
  const handleLanguageSelect = (langObject: LangType) => {
    console.log(langObject, '----------------');
    i18next.changeLanguage(langObject.code)
    setSelectedLanguage(langObject)
    localStorage.setItem(CACHE_KEY, langObject.code)
    window.location.reload()
  }

  function UnConnect() {
    logout();
    const connectorLocalStorageKey = "connectorId";
    window.localStorage.removeItem(connectorLocalStorageKey);
    setWalletPop(false)
  }

  async function doWithdraw(bankid: string) {
    handler.current = Toast.show({
      icon: 'loading',
      duration: 0,
      maskClickable: false,
    })
    try {
      if (typeof(library) === 'object' && typeof(account) === 'string') {
        const resnonce: ApiResponse<any> = await AxiosUtil.request({ url: '/api/v1/publicapi/getnonce', method: 'POST', data: { address }})
        const nonce: string = resnonce?.data?.nonce
        const signer = getSigner(library, address)
        const signature = await signer.signMessage(ethers.utils.arrayify(ethers.utils.toUtf8Bytes(nonce)))
        const result: ApiResponse<any> = await AxiosUtil.request({
          url: '/api/v1/publicapi/tokengetsave',
          method: 'POST',
          data: {
            bankid,
            address,
            getnum: bankid === '10001' ? ptbnum : usdtnum,
            signature
          }
        })
        console.log(result);
        const orderid = result.data.orderid
        const decimals = bankid === '10001' ? currencyTokenA?.decimals : currencyTokenB?.decimals
        const amount = new BigNumber(result.data.getnum).multipliedBy(10 ** Number(decimals)).toFixed()
        const args = [
          orderid, amount
        ]
        const RechargeContract = getRechargeContract(library, account)
        const gasFree = await RechargeContract.gasFee()
        const estimate = await RechargeContract.estimateGas.fee(...args, { value: gasFree })
        console.log(estimate.toString());
        const loads = await RechargeContract.fee(...args, { value: gasFree })
        await loads.wait()
        handler.current?.close()
        Toast.show({content: t('领取成功')})
        setWalletPop(false)
      } else {
        handler.current?.close()
      }
    }
    catch (e) {
      errorformat(e)
      handler.current?.close()
    }
  }

  function checkWithDraw(bankid: string): any {
    if (bankid === '10001' && !Number(ptbnum) || bankid === '10002' && !Number(usdtnum)) {
      Toast.show({content: t('余额不足')})
      return
    }
    doWithdraw(bankid)
  }
  // useGetDocumentTitlePrice()

  return (
    <Suspense fallback={null}>
      <HashRouter>
        <AppWrapper>
          <LanguageContext.Provider
            value={{ selectedLanguage, setSelectedLanguage: handleLanguageSelect, translatedLanguage, setTranslatedLanguage }}
          >
            <TranslationsContext.Provider value={{ translations, setTranslations }}>
              <Menu>
                <BodyWrapper>
                  <Popups />
                  <Web3ReactManager>
                    <Switch>
                      <Route exact strict path="/subscribe" component={Subscribe} />
                      <Route exact strict path="/swap" component={Swap} />
                      <Route exact strict path="/find" component={PoolFinder} />
                      <Route exact strict path="/pool" component={Pool} />
                      <Route exact path="/add" component={AddLiquidity} />
                      <Route exact path="/addtoken" component={AddToken} />
                      <Route exact strict path="/remove/:currencyIdA/:currencyIdB" component={RemoveLiquidity} />
                      <Route exact path="/detail/:currencyIdA/:currencyIdB" component={LiquidityDetail} />
                      {/* Redirection: These old routes are still used in the code base */}
                      <Route exact path="/add/:currencyIdA" component={RedirectOldAddLiquidityPathStructure} />
                      <Route exact path="/add/:currencyIdA/:currencyIdB" component={RedirectDuplicateTokenIds} />
                      <Route exact strict path="/remove/:tokens" component={RedirectOldRemoveLiquidityPathStructure} />
                      <Route exact path="/team" component={Team} />
                      <Route component={RedirectPathToSwapOnly} />
                    </Switch>
                  </Web3ReactManager>
                  {/* <Marginer /> */}
                </BodyWrapper>
              </Menu>
              <Popup
                visible={walletPop}
                onMaskClick={() => {
                  setWalletPop(false)
                }}
                onClose={() => {
                  setWalletPop(false)
                }}
                bodyStyle={{
                  minHeight: '60vh',
                  borderTopLeftRadius: '20px',
                  borderTopRightRadius: '20px',
                }}
              >
                <WalletBox>
                  <div className="header">
                    <div className="title">{t('我的钱包')}</div>
                    <Button className="close" onClick={() => {setWalletPop(false)}} />
                  </div>
                  <div className="content">
                  <div className="subtitle">{t('您的地址')}</div>
                    <div className="address-box">
                      <span>{account}</span>
                      <Button className="address-copy" onClick={
                        () => {
                          copy(address)
                          Toast.show({content: t('复制成功')})
                        }}
                      />
                    </div>
                    <div className="chain-box">
                      <div className="left">
                        <img src="./images/bnbchain.png" alt="" />
                        <span>Binance Smart Chain Testnet</span>
                      </div>
                      <a href={`https://testnet.bscscan.com/address/${address}`} target='_black' className="right">
                        <span>BscScan</span>
                        <img src="./images/net@2x.png" alt="" />
                      </a>
                    </div>
                    <div className="balance">
                      <div className="title">tBNB {t('Balance')}</div>
                      <div className="num">{banance}</div>
                    </div>
                    <div className="chain-box">
                      <div className="left">
                        <img src="./images/bnbchain.png" alt="" />
                        <span>BNB Smart Chain</span>
                      </div>
                      <a href={`https://bscscan.com/address/${address}`} target='_black' className="right">
                        <span>BscScan</span>
                        <img src="./images/net@2x.png" alt="" />
                      </a>
                    </div>
                    {/* <div className="balance">
                      <div className="title">{t('身份信息')}</div>
                      <div className="num">{lvname}</div>
                    </div> */}
                    {/* <div className="fenhong">
                      <div className="title">LPC {t('Balance')}</div>
                      <div className="nums">
                        <span>{ptbnum} LPC</span>
                        <Button className='btn' onClick={() => checkWithDraw('10001')}>{t('领取')}</Button>
                      </div>
                    </div>
                    <div className="fenhong">
                      <div className="title">{t('节点分红')}</div>
                      <div className="nums">
                        <span>{usdtnum} USDT</span>
                        <Button className='btn' onClick={() => checkWithDraw('10002')}>{t('领取')}</Button>
                      </div>
                    </div> */}
                    {/* <div className="order-btn" onClick={UnConnect(logout)}>断开钱包</div> */}
                    <Button className="order-btn" block onClick={UnConnect}>{t('断开钱包')}</Button>
                  </div>
                </WalletBox>
              </Popup>
              <FooterContainer lang={selectedLanguage?.language} />
            </TranslationsContext.Provider>
          </LanguageContext.Provider>
        </AppWrapper>
      </HashRouter>
    </Suspense>
  )
}
