import React, { useRef } from 'react'
import styled from 'styled-components'
// import { Link } from 'react-router-dom'
// import { ButtonMenu, ButtonMenuItem } from '@pancakeswap-libs/uikit'
// import useI18n from 'hooks/useI18n'
import { Toast } from 'antd-mobile'
import type { ToastHandler } from 'antd-mobile/es/components/toast'
import { useTranslation } from 'react-i18next'
import AxiosUtil from 'utils/AxiosUtil'

// const StyledNav = styled.div`
//   margin-bottom: 40px;
// `
const StyleNavigate = styled.div`
  width: 100%;
  background: #fff;
  display: flex;
  button {
    flex: 1;
    min-width: 0;
    flex-shrink: 0;
    height: 44px;
    line-height: 44px;
    text-align: center;
    font-size: 15px;
    color: #111111;
		overflow: hidden;
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    background: transparent;
  }
  a,span {
    flex: 1;
    min-width: 0;
    flex-shrink: 0;
    height: 44px;
    line-height: 44px;
    text-align: center;
    font-size: 15px;
    color: #111111;
		overflow: hidden;
    &.active {
      color: #8660DC;
      position: relative;
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 30px;
        height: 3px;
        background: #3EC8D4;
      }
    }
  }
`

function Nav({ activeIndex = 0 }: { activeIndex?: number }) {
  
  const { t } = useTranslation()
  const handler = useRef<ToastHandler>()
  const firstName = window.location.origin;
  const lastName = "/pledge/#/";
  const PledgeUrl = `${firstName}${lastName}`;
  const brigeUrl = 'https://swap.transit.finance'
  const IsCanHref = () => {
    handler.current = Toast.show({
      content: 'Loading...',
      icon: 'loading',
      duration: 0,
      maskClickable: false,
    })
    AxiosUtil.request({
      url: '/api/v1/publicapi/openindex',
      method: 'POST',
      data: {
        type: '1007'
      }
    }).then(res => {
      handler.current?.close()
      const result:any = res
      if (result.data.state !== 1) {
        Toast.show({
          content: t('敬请期待')
        })
      } else {
        window.location.href = PledgeUrl
      }
    }).catch(() => {
      handler.current?.close()
    })
  }

  // const doPledgeUrl = () => {
  //   window.location.href = PledgeUrl
  // }  
  return (
    <StyleNavigate>
      <a href="#/swap" className={`${activeIndex === 0 && "active"}`}>{t('兑换')}</a>
      <a href="#/pool" className={`${activeIndex === 1 && "active"}`}>{t('流动性')}</a>
      {/* <span>{t('流动性')}</span> */}
      <a href={`${brigeUrl}`}>{t('跨链桥')}</a>
      {/* <a href={`${PledgeUrl}`} className={`${activeIndex === 3 && "active"}`}>{t('矿池')}</a> */}
      <a href="#/subscribe" className={`${activeIndex === 3 && "active"}`}>{t('节点认购')}</a>
      {/* <button type="button" onClick={() => IsCanHref()}>{t('矿池')}</button> */}
    </StyleNavigate>
    // <StyledNav>
    //   <ButtonMenu activeIndex={activeIndex} scale="sm" variant="subtle">
    //     <ButtonMenuItem id="swap-nav-link" to="/swap" as={Link}>
    //       {TranslateString(1142, 'Swap')}
    //     </ButtonMenuItem>
    //     <ButtonMenuItem id="pool-nav-link" to="/pool" as={Link}>
    //       {TranslateString(262, 'Liquidity')}
    //     </ButtonMenuItem>
    //     {/* <ButtonMenuItem
    //       id="pool-nav-link"
    //       as="a"
    //       href="https://www.binance.org/en/bridge?utm_source=PancakeSwap"
    //       target="_blank"
    //       rel="noreferrer noopener"
    //     >
    //       Bridge
    //     </ButtonMenuItem> */}
    //   </ButtonMenu>
    // </StyledNav>
  )
}

export default Nav
