import { MenuEntry } from '@pancakeswap-libs/uikit'

const firstName = window.location.origin;
const lastName = "/pledge/#/";
const PledgeUrl = `${firstName}${lastName}`;


const config: MenuEntry[] = [
  // {
  //   label: 'Home',
  //   icon: 'HomeIcon',
  //   href: '/',
  // },
  // {
  //   label: 'Trade',
  //   icon: 'TradeIcon',
  //   initialOpenState: false,
  //   items: [
  //     {
  //       label: 'Exchange',
  //       href: '/swap',
  //     },
  //     {
  //       label: 'Liquidity',
  //       href: '/pool',
  //     }
  //   ],
  // },
  // {
  //   label: 'SERO',
  //   icon: 'NftIcon',
  //   href: 'https://sero.goer.cloud',
  // },
  {
    label: 'Swap',
    icon: 'TradeIcon',
    href: '/swap',
  },
  {
    label: 'Liquidity',
    icon: 'NftIcon',
    href: '/pool',
  },
  {
    label: "跨链桥",
    icon: "HomeIcon",
    href: 'https://swap.transit.finance',
  },
  // {
  //   label: "矿池",
  //   icon: "InfoIcon",
  //   href: `${PledgeUrl}`,
  // },
  {
    label: "节点认购",
    icon: "InfoIcon",
    href: '/subscribe',
  }
]

export default config
