import React, { useContext, useMemo, useState } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { Pair } from '@pancakeswap-libs/sdk'
import { Button, CardBody, Text } from '@pancakeswap-libs/uikit'
// import { Link } from 'react-router-dom'
import CardNav from 'components/CardNav'
import Question from 'components/QuestionHelper'
import FullPositionCard from 'components/PositionCard'
import { useTokenBalancesWithLoadingIndicator } from 'state/wallet/hooks'
import { StyledInternalLink } from 'components/Shared'
import { LightCard } from 'components/Card'
import { RowBetween } from 'components/Row'
import { AutoColumn } from 'components/Column'
import Container from 'components/Container'
import { useHistory } from 'react-router-dom'
import { useActiveWeb3React } from 'hooks'
import { usePairs } from 'data/Reserves'
import { toV2LiquidityToken, useTrackedTokenPairs } from 'state/user/hooks'
import { Dots } from 'components/swap/styleds'
import { useTranslation } from 'react-i18next'
import PageHeader from 'components/PageHeader'
import { Popup, Button as AntButton, Toast } from 'antd-mobile'
import AppBody from '../AppBody'

const CreateBox = styled.div`
  .header {
    height: 80px;
    border-bottom: 1px solid #E9E4F3;
    padding: 0 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: url("./images/walletBg.png") left top/100% 100% no-repeat;
    .title {
      font-size: 20px;
      font-family: Kanit, sans-serif;
      font-weight: bold;
      color: #2B106B;
    }
    .close {
      width: 18px;
      height: 18px;
      background: url("./images/popclose.png") left top/100% 100% no-repeat;
      padding: 0;
      border: none;
      border-radius: 0;
      box-shadow: none;
    }
  }
  .content {
    overflow: hidden;
    word-break: break-all;
    overflow-y: scroll;
    padding: 0 24px 24px;
    .infos {
      .title {
        font-size: 18px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #7B72A9;
        line-height: 25px;
        margin-bottom: 10px;
        margin-top: 24px;
      }
      .line {
        font-size: 18px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #7B72A9;
        line-height: 25px;
      }
    }
    .order-btn {
      margin-top: 30px;
      height: 50px;
      background: #3EC8D4;
      border-radius: 16px 16px 16px 16px;
      font-size: 16px;
      font-family: Kanit, sans-serif;
      box-shadow: 0px 1px 0px 0px #44ACB9;
      font-weight: bold;
      color: #FFFFFF;
      border: none;
      outline: none;
    }
  }
`

export default function Pool() {
  const [createPop, setCreatePop] = useState<boolean>(false)
  const theme = useContext(ThemeContext)
  const { account } = useActiveWeb3React()
  const { t } = useTranslation()
  const history = useHistory()

  // fetch the user's balances of all tracked V2 LP tokens
  const trackedTokenPairs = useTrackedTokenPairs()
  const tokenPairsWithLiquidityTokens = useMemo(
    () => trackedTokenPairs.map((tokens) => ({ liquidityToken: toV2LiquidityToken(tokens), tokens })),
    [trackedTokenPairs]
  )
  const liquidityTokens = useMemo(() => tokenPairsWithLiquidityTokens.map((tpwlt) => tpwlt.liquidityToken), [
    tokenPairsWithLiquidityTokens,
  ])
  const [v2PairsBalances, fetchingV2PairBalances] = useTokenBalancesWithLoadingIndicator(
    account ?? undefined,
    liquidityTokens
  )

  // fetch the reserves for all V2 pools in which the user has a balance
  const liquidityTokensWithBalances = useMemo(
    () =>
      tokenPairsWithLiquidityTokens.filter(({ liquidityToken }) =>
        v2PairsBalances[liquidityToken.address]?.greaterThan('0')
      ),
    [tokenPairsWithLiquidityTokens, v2PairsBalances]
  )

  const v2Pairs = usePairs(liquidityTokensWithBalances.map(({ tokens }) => tokens))
  const v2IsLoading =
    fetchingV2PairBalances || v2Pairs?.length < liquidityTokensWithBalances.length || v2Pairs?.some((V2Pair) => !V2Pair)

  const allV2PairsWithLiquidity = v2Pairs.map(([, pair]) => pair).filter((v2Pair): v2Pair is Pair => Boolean(v2Pair))

  function ClickIKnow() {
    setCreatePop(false)
    if (process.env.REACT_APP_CODE_HASH === '') {
      Toast.show({
        content: t('敬请期待')
      })
    } else {
      history.push('/add/BNB')
    }
  }
  const doToast = () => {
    Toast.show({
      content: t('敬请期待')
    })
  }

  return (
    <>
      <CardNav activeIndex={1} />
      <Container>
        <AppBody>
          <PageHeader
              title={t('流动性')}
              description={t('Add liquidity to receive LP tokens')}
              type="pool"
          >
            {/* <Button id="join-pool-button" as={Link} to="/add/BNB">
              {t('addLiquidity')}
            </Button> */}
            <Button id="join-pool-button" onClick={() => {setCreatePop(true)}}>
              {t('addLiquidity')}
            </Button>
          </PageHeader>
          <AutoColumn gap="lg" justify="center">
            <CardBody>
              <AutoColumn gap="12px" style={{ width: '100%' }}>
                <RowBetween padding="0 8px">
                  <Text color={theme.colors.text}>{t('Your Liquidity')}</Text>
                  <Question
                      text={t('When you add liquidity, you are given pool tokens that represent your share. If you don’t see a pool you joined in this list, try importing a pool below.'
                      )}
                  />
                </RowBetween>

                {!account ? (
                    <LightCard padding="40px">
                      <Text color="textDisabled" textAlign="center">
                        {t('Connect to a wallet to view your liquidity.')}
                      </Text>
                    </LightCard>
                ) : v2IsLoading ? (
                    <LightCard padding="40px">
                      <Text color="textDisabled" textAlign="center">
                        <Dots>Loading</Dots>
                      </Text>
                    </LightCard>
                ) : allV2PairsWithLiquidity?.length > 0 ? (
                    <>
                      {allV2PairsWithLiquidity.map((v2Pair) => (
                          <FullPositionCard key={v2Pair.liquidityToken.address} pair={v2Pair} />
                      ))}
                    </>
                ) : (
                    <LightCard padding="40px">
                      <Text color="textDisabled" textAlign="center">
                        {t('No liquidity found.')}
                      </Text>
                    </LightCard>
                )}
                {/* {!account ? (
                    <LightCard padding="40px">
                      <Text color="textDisabled" textAlign="center">
                        {t('Connect to a wallet to view your liquidity.')}
                      </Text>
                    </LightCard>
                ) : (
                  <LightCard padding="40px">
                    <Text color="textDisabled" textAlign="center">
                      {t('No liquidity found.')}
                    </Text>
                  </LightCard>
                )} */}

                <div>
                  <Text fontSize="14px" style={{ padding: '.5rem 0 .5rem 0' }}>
                    {t("Don't see a pool you joined?")}{' '}
                    { process.env.REACT_APP_CODE_HASH === '' ? (
                      <Text onClick={doToast} style={{display: 'inline', color: '#3EC8D4', fontSize: '14px'}}>
                        {t('Import it.')}
                      </Text>
                    ) : (
                      <StyledInternalLink id="import-pool-link" to="/find">
                        {t('Import it.')}
                      </StyledInternalLink>
                    ) }
                  </Text>
                  <Text fontSize="14px" style={{ padding: '.5rem 0 .5rem 0' }}>
                    {t('Or, if you staked your LP tokens in a farm, unstake them to see them here.')}
                  </Text>
                </div>
              </AutoColumn>
            </CardBody>
          </AutoColumn>
        </AppBody>
      </Container>
      <Popup
        visible={createPop}
        onMaskClick={() => {
          setCreatePop(false)
        }}
        onClose={() => {
          setCreatePop(false)
        }}
        bodyStyle={{
          borderTopLeftRadius: '20px',
          borderTopRightRadius: '20px',
        }}
      >
        <CreateBox>
          <div className="header">
            <div className="title">{t('您正在创建交易对')}</div>
            <Button className="close" onClick={() => {setCreatePop(false)}} />
          </div>
          <div className="content">
            <div className="infos">
              <div className="title">{t('请须知')}：</div>
              <div className="line">{t('其中数量少的代币为主流币')}</div>
              <div className="line">{t('此LP移除时全部换成主流币')}</div>
            </div>
            <AntButton className="order-btn" block onClick={ClickIKnow}>{t('我已知晓')}</AntButton>
          </div>
        </CreateBox>
      </Popup>
    </>
  )
}
