import { Token } from '@pancakeswap-libs/sdk'
import AxiosUtil from 'utils/AxiosUtil'
import { isAddress } from '../../utils'

// export function getImageByAddress():Promise<any> {
  
//   return new Promise((resolve, reject) => {
//     AxiosUtil.request({
//       url: '/api/v1/infoapi/tokenaddrfind',
//       method: 'POST',
//       data: {}
//     }).then(res => {
//       resolve(res)
//     }).catch(e => {
//       reject(e)
//     })
//   })
// }

export function getTokenindex():Promise<any> {
  
  return new Promise((resolve, reject) => {
    AxiosUtil.request({
      url: '/api/v1/infoapi/tokenindex',
      method: 'POST',
      data: {}
    }).then(res => {
      resolve(res)
    }).catch(e => {
      reject(e)
    })
  })
}

export function getTokenindexByName(name: string):Promise<any> {
  
  return new Promise((resolve, reject) => {
    AxiosUtil.request({
      url: '/api/v1/infoapi/tokenfind',
      method: 'POST',
      data: {
        name
      }
    }).then(res => {
      resolve(res)
    }).catch(e => {
      reject(e)
    })
  })
}

interface indexToken {
  name: string
  imgurl: string
  address: string
}

export function filterTokens(tokens: Token[], search: string, indexTokenList: indexToken[] ): Token[] {
  if (search.length === 0) return tokens

  const searchingAddress = isAddress(search)

  if (searchingAddress) {
    return tokens.filter((token) => token.address === searchingAddress)
  }

  const lowerSearchParts = search
    .toLowerCase()
    .split(/\s+/)
    .filter((s) => s.length > 0)

  if (lowerSearchParts.length === 0) {
    return tokens
  }
  // console.log(indexTokenList);
  // console.log(indexTokenList);
  // console.log(tokens);
  
  const BastTokens: Token[] = []
  tokens.forEach(item => {
    indexTokenList.forEach(res => {
      if (res.address === item.address) {
        BastTokens.push(item)
      }
    })
  })
  // console.log(BastTokens);
  
  
  const matchesSearch = (s: string): boolean => {
    const sParts = s
      .toLowerCase()
      .split(/\s+/)
      .filter((str) => str.length > 0)

    return lowerSearchParts.every((p) => p.length === 0 || sParts.some((sp) => sp.startsWith(p) || sp.endsWith(p)))
  }

  return tokens.filter((token) => {
    const { symbol, name } = token

    return (symbol && matchesSearch(symbol)) || (name && matchesSearch(name))
  })
}

export default filterTokens
