import { MaxUint256 } from '@ethersproject/constants'
import React, { useEffect, useState, useRef, useCallback } from 'react'
import { useWeb3React as useWeb3ReactCore } from '@web3-react/core'
import { Link as HistoryLink } from 'react-router-dom'
import BigNumber from "bignumber.js";
import styled from 'styled-components'
import AxiosUtil, {ApiResponse} from 'utils/AxiosUtil'
import { getSigner, getDepositContract, errorformat } from 'utils'
import { ethers } from 'ethers'
import { Popup, Button, Toast } from 'antd-mobile'

import type { ToastHandler } from 'antd-mobile/es/components/toast'

import CardNav from 'components/CardNav'
import Container from 'components/Container'
import { useTranslation } from 'react-i18next';
import { useActiveWeb3React } from '../../hooks'
import { useToken } from '../../hooks/Tokens'
import { useTokenContract } from '../../hooks/useContract'
// import { getNetworkLibrary } from '../../connectors'

const AddressPop = styled.div`
  padding: 30px 24px 32px;
  .title {
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 14px;
    color: #2B106B;
    line-height: 20px;
    text-align: center;
  }
  .inputBox {
    height: 50px;
    background: #EEEAF4;
    box-shadow: inset 1px 1px 4px 0px rgba(173,107,238,0.1), inset -1px -1px 4px 0px rgba(173,107,238,0.1);
    border-radius: 16px;
    margin-top: 16px;
    input {
      display: block;
      width: 100%;
      height: 50px;
      font-size: 16px;
      color: #2B106B;
      border: none;
      outline: none;
      background: transparent;
      padding: 0 12px;
    }
  }
`
const MainBox = styled.div`
  width: 100%;
  .team {
    font-family: PingFang SC, PingFang SC;
    font-weight: bold;
    font-size: 16px;
    color: #8660DC;
    line-height: 50px;
    text-align: right;
  }
  .box {
    background: #FFFFFF;
    box-shadow: 0px 2px 0px 0px #E7E3EB;
    border-radius: 24px;
    padding: 24px;
    margin: 0 auto 12px;
    max-width: 436px;
    .title {
      font-family: PingFang SC, PingFang SC;
      font-weight: bold;
      font-size: 14px;
      color: #2B106B;
      line-height: 20px;
    }
    .inputBox {
      height: 50px;
      background: #EEEAF4;
      box-shadow: inset 1px 1px 4px 0px rgba(173,107,238,0.1), inset -1px -1px 4px 0px rgba(173,107,238,0.1);
      border-radius: 16px;
      margin-top: 16px;
      position: relative;
      .sp1 {
        font-family: PingFang SC, PingFang SC;
        font-weight: bold;
        font-size: 16px;
        color: #7B72A9;
        position: absolute;
        right: 12px;
        top: 50%;
        transform: translateY(-50%);
      }
      input {
        display: block;
        width: 100%;
        height: 50px;
        font-size: 16px;
        color: #2B106B;
        border: none;
        outline: none;
        background: transparent;
        padding: 0 12px;
      }
    }
    .tips {
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 12px;
      color: #7B72A9;
      line-height: 17px;
      margin-top: 12px;
    }
  }
`

const Subscribe = () => {
  const { t } = useTranslation();
  const handler = useRef<ToastHandler>()
  const handler2 = useRef<ToastHandler>()
  const handler3 = useRef<ToastHandler>()
  const { account, library } = useActiveWeb3React()
  const [address, setAddress] = useState<string>('')
  const [list, setList] = useState<Array<any>>([])
  const [bindshow, setBindshow] = useState(false)
  const [loading, setLoading] = useState(false)
  const [inputValue, setInputValue] = useState<string>('')
  const [allowance, setAllowance] = useState<string>('')
  const [decimals, setDecimals] = useState<number>(0)
  const [balance, setBalance] = useState<string>('')
  const [isclose, setIsclose] = useState<number>(0)
  const usdtAddress:string = process.env.REACT_APP_USDT_ADDRESS || ''
  const USDT = useToken(usdtAddress)
  const tokenContract = useTokenContract(USDT?.address)
  const DEPOSIT_ADDRESS:string = process.env.REACT_APP_DEPOSIT_ADDRESS ? process.env.REACT_APP_DEPOSIT_ADDRESS : ''
  const { active } = useWeb3ReactCore()
  
  
  // 查询授权额度
  async function getUsdtinfo () {
    handler2.current = Toast.show({
      content: 'Loading Coins',
      icon: 'loading',
      duration: 0,
      maskClickable: false,
    })
    const allowance2 = await tokenContract?.allowance(address, DEPOSIT_ADDRESS)
    await setAllowance(allowance2.toString())
    const balance2 = await tokenContract?.balanceOf(address)
    await setBalance(balance2.toString())
    handler2.current?.close()
  }

  // useEffect(() => {
  //   if (typeof(tokenContract) === 'object' && address) {
  //     getUsdtinfo()
  //   }
  // }, [tokenContract, getUsdtinfo, address])

  const getHomeinfo = useCallback(async () => {
    handler.current = Toast.show({
      content: 'Loading Infos',
      icon: 'loading',
      duration: 0,
      maskClickable: false,
    })
    try {
      if (account && typeof(account) === 'string' && typeof(library) === 'object' && typeof(tokenContract) === 'object') {
        const DepositContract = getDepositContract(library, account)
        // console.log(DepositContract);
        if (typeof(DepositContract) === 'object') {
          const intross = await DepositContract.introInfos(account)
          console.log(intross);
          
          if (intross && intross !== '0x0000000000000000000000000000000000000000') {
            handler.current?.close()
            setBindshow(false)
            AxiosUtil.request({
              url: '/api/v1/ipoapi/index',
              method: 'POST',
              data: {
                address: account
              }
            }).then(async res => {
              const result: any = res
              handler3.current = Toast.show({
                content: 'Loading Coins',
                icon: 'loading',
                duration: 0,
                maskClickable: false,
              })
              const allowance1 = await tokenContract?.allowance(account, DEPOSIT_ADDRESS)
              await setAllowance(String(allowance1))
              const balance1 = await tokenContract?.balanceOf(account)
              await setBalance(String(balance1))
              setList([ ...result?.data.cons ])
              setIsclose(Number(result?.data.isclose))
              handler3.current?.close()
            }).catch(e => {
              errorformat(e)
            })
          } else {
            handler.current?.close()
            setBindshow(true)
          }
        }
      }
      
    } catch (e) {
      handler.current?.close()
      errorformat(e)
    }
  }, [account, tokenContract, library, DEPOSIT_ADDRESS])

  useEffect(() => {
    if (account && typeof(account) === 'string' && typeof(library) === 'object' && typeof(tokenContract) === 'object') {
      getHomeinfo()
      setAddress(account)
    }
  }, [account, tokenContract, getHomeinfo, library])

  useEffect(() => {
    if (typeof(USDT) === 'object') {
      setDecimals(Number(USDT?.decimals))
    }
  }, [USDT])

  const handleInput = useCallback(
    (event) => {
      const input = event.target.value
      setInputValue(input)
    },
    [setInputValue]
  )

  const doApprove = async (money) => {
    setLoading(true)
    try {
      // await tokenContract?.estimateGas.approve(DEPOSIT_ADDRESS, MaxUint256)
      // const createReceipt = await tokenContract?.approve(DEPOSIT_ADDRESS, MaxUint256)
      // await createReceipt.wait();
      const amount = new BigNumber(money).multipliedBy(10 ** decimals).toFixed()
      await tokenContract?.estimateGas.approve(DEPOSIT_ADDRESS, amount)
      const createReceipt = await tokenContract?.approve(DEPOSIT_ADDRESS, amount)
      await createReceipt.wait();
      setLoading(false)
      Toast.show({
        content: t('授权成功'),
        afterClose: () => {
          window.location.reload()
        }
      })
      // await getUsdtinfo()
    } catch (e) {
      errorformat(e)
      setLoading(false)
    }
  }

  const doDeposit = async item => {
    // Toast.show({content: t('敬请期待')})
    if (new BigNumber(item.money).multipliedBy(10 ** decimals).gt(balance)) {
      return Toast.show({content: t('余额不足')})
    }
    setLoading(true)
    try {
      if (typeof(library) === 'object' && typeof(account) === 'string') {
        const resnonce: ApiResponse<any> = await AxiosUtil.request({ url: '/api/v1/publicapi/getnonce', method: 'POST', data: { address }})
        const nonce: string = resnonce?.data?.nonce
        const signer = getSigner(library, address)
        const signature = await signer.signMessage(ethers.utils.arrayify(ethers.utils.toUtf8Bytes(nonce)))
        const result: ApiResponse<any> = await AxiosUtil.request({
          url: '/api/v1/publicapi/buyTokenSave',
          method: 'POST',
          data: {
            address,
            lv: item.lv.toString(),
            signature
          }
        })
        // console.log(result);
        const orderid = result.data.orderid.toString()
        const lv = result.data.lv.toString()
        const tokenaddress = usdtAddress
        const amount = new BigNumber(result.data.num).multipliedBy(10 ** Number(decimals)).toFixed()
        const args = [
          orderid, lv, tokenaddress, amount
        ]
        const DepositContract = getDepositContract(library, account)
        const estimate = await DepositContract.estimateGas.depositOrder(...args)
        const createReceipt = await DepositContract.depositOrder(...args)
        await createReceipt.wait();
        await Toast.show({
          content: t('认购成功')
        })
        await setLoading(false)
        await getUsdtinfo()
      } else {
        setLoading(false)
      }
    } catch (e: any) {
      const errorStr: string = JSON.stringify(e);
      console.log(errorStr);
      if (errorStr.indexOf('deposit max') !== -1 || errorStr.indexOf('only once') !== -1) {
        setLoading(false)
        Toast.show(t('认购失败,超出认购数量'))
      } else if (errorStr.indexOf('UNPREDICTABLE_GAS_LIMIT') !== -1 || errorStr.indexOf('cannot estimate gas') !== -1) {
        setLoading(false)
        Toast.show('cannot estimate gas')
      } else {
        errorformat(e)
      }
      setLoading(false)
    }
    return ''
  }

  async function handleBindClick() {
    if (!inputValue) {
      return Toast.show({content: t('请先绑定推荐人地址')})
    }
    setLoading(true)
    try {
      if (typeof(library) === 'object' && typeof(account) === 'string') {
        // const resnonce: ApiResponse<any> = await AxiosUtil.request({ url: '/api/v1/publicapi/getnonce', method: 'POST', data: { address }})
        // const nonce: string = resnonce?.data?.nonce
        // const signer = getSigner(library, address)
        // const signature = await signer.signMessage(ethers.utils.arrayify(ethers.utils.toUtf8Bytes(nonce)))
        // const result: ApiResponse<any> = await AxiosUtil.request({
        //   url: '/api/v1/publicapi/bindRecSave',
        //   method: 'POST',
        //   data: { 
        //     address,
        //     recaddress: inputValue,
        //     signature 
        //   }
        // })
        const DepositContract = getDepositContract(library, account)
        let gasFee = await DepositContract.introGas()
        console.log(gasFee);
        
        gasFee = Number(gasFee).toFixed()
        console.log(gasFee);
        await DepositContract.estimateGas.bind(inputValue, { value: gasFee })
        const createBind = await DepositContract.bind(inputValue, { value: gasFee })
        await createBind.wait()
        setLoading(false)
        Toast.show({
          content: t('绑定成功'),
          afterClose: () => {
            window.location.reload()
          }
        })
      } else {
        setLoading(false)
      }
    }
    catch (e: any) {
      const errorStr: string = JSON.stringify(e);
      console.log(errorStr);
      if (errorStr.indexOf('wrong intro') !== -1) {
        setLoading(false)
        Toast.show(t('推荐地址未绑定无法绑定'))
      } else {
        errorformat(e)
      }
      setLoading(false)
    }
    return ''
  }

  return (
    <>
      <CardNav activeIndex={3} />
      <Container style={{paddingTop:'0px'}}>
        <MainBox>
          <HistoryLink to="/team">
            <div className="team">{t('矿池信息')}</div>
          </HistoryLink>
          {
            list.map(item => {
              return (
                <div className="box" key={item.lv}>
                  <div className="title">{item.name}{t('认购')}</div>
                  <div className="inputBox">
                    <input type="text" value={item.money} readOnly />
                    <div className="sp1">USDT</div>
                  </div>
                  {
                    Number(allowance) <= 0 || new BigNumber(item.money).multipliedBy(10 ** decimals).gt(allowance) ? (
                      <Button block fill='solid' size='large'
                        loading={loading}
                        disabled={loading}
                        onClick={() => doApprove(item.money)}
                        style={{
                          color: '#FFFFFF',
                          border: 'none',
                          borderRadius: '12px',
                          fontSize: '16px',
                          marginTop: '34px',
                          background: '#3EC8D4'
                        }}>
                        {t('授权USDT')}
                      </Button>
                    ) : Number(isclose) === 1 ? <Button block fill='solid' size='large'
                        onClick={() => Toast.show(t('敬请期待'))}
                        style={{
                          color: '#FFFFFF',
                          border: 'none',
                          borderRadius: '12px',
                          fontSize: '16px',
                          marginTop: '34px',
                          background: '#3EC8D4'
                        }}>
                        {t('立即支付')}
                      </Button> :  (
                      <Button block fill='solid' size='large'
                        loading={loading}
                        disabled={loading}
                        onClick={() => doDeposit(item)}
                        style={{
                          color: '#FFFFFF',
                          border: 'none',
                          borderRadius: '12px',
                          fontSize: '16px',
                          marginTop: '34px',
                          background: '#3EC8D4'
                        }}>
                        {t('立即支付')}
                      </Button>
                    )
                  }
                  {/* <div className="tips">
                    {t('说明')}：<br />{t('说明')}<br />
                  </div> */}
                </div>
              )
            })
          }
        </MainBox>
      </Container>
      <Popup
        visible={bindshow}
        onClose={() => {
          setBindshow(false)
        }}
        bodyStyle={
          {
            borderTopLeftRadius: '24px',
            borderTopRightRadius: '24px'
          }
        }
      >
        <AddressPop>
          <div className='title'>{t('请先绑定推荐人地址')}</div>
          <div className="inputBox">
            <input type="text" value={inputValue} onChange={handleInput} />
          </div>
          <Button block fill='solid' size='large' onClick={handleBindClick}
            loading={loading}
            disabled={loading}
            style={{
              color: '#FFFFFF',
              border: 'none',
              borderRadius: '12px',
              fontSize: '16px',
              marginTop: '34px',
              background: '#3EC8D4'
            }}>
            {t('确定')}
          </Button>
        </AddressPop>
      </Popup>
    </>
  )
}

export default Subscribe
