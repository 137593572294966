import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { Heading, IconButton, Text, Flex, useModal } from '@pancakeswap-libs/uikit'
import useI18n from 'hooks/useI18n'
import { useTranslation } from 'react-i18next'
import SettingsModal from './SettingsModal'
import RecentTransactionsModal from './RecentTransactionsModal'
// import PartitionSelect from '../PartitionSelect';

interface PageHeaderProps {
  title: ReactNode
  description?: ReactNode
  children?: ReactNode
  type: string
}

const StyledPageHeader = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};
  padding: 24px;
`

const Details = styled.div`
  flex: 1;
`

const PageHeader = ({ title, description, children, type }: PageHeaderProps) => {
  const { t } = useTranslation()
  const TranslateString = useI18n()
  const [onPresentSettings] = useModal(<SettingsModal translateString={TranslateString} />)
  const [onPresentRecentTransactions] = useModal(<RecentTransactionsModal translateString={TranslateString} />)

  return (
    <StyledPageHeader>
      <Flex alignItems="center">
        <Details>
          <Heading mb="8px">{title}</Heading>
          {description && (
            <Text color="textSubtle" fontSize="14px">
              {description}
            </Text>
          )}
        </Details>
        <IconButton variant="text" onClick={onPresentSettings} title={t('Settings')}>
          {/* <TuneIcon width="24px" color="currentColor" /> */}
          <img width="24px" src="./images/Turn@2x.png" alt=""/>
        </IconButton>
        <IconButton
          variant="text"
          onClick={onPresentRecentTransactions}
          title={t('Recent transactions')}
        >
          {/* <HistoryIcon width="24px" color="currentColor" /> */}
          <img width="24px" src="./images/History@2x.png" alt=""/>
        </IconButton>
      </Flex>
      {/* <PartitionSelect isShowSelect={type === "swap"} type={type} /> */}
      {children && <Text mt="16px">{children}</Text>}
    </StyledPageHeader>
  )
}

export default PageHeader
