import React, { useEffect, useState, useRef } from 'react'
// import { Token } from '@pancakeswap-libs/sdk'
import BigNumber from "bignumber.js";
// import { TransactionResponse } from '@ethersproject/providers'
import styled from 'styled-components'
import { RouteComponentProps, Link as HistoryLink } from 'react-router-dom'
import { ArrowLeft } from 'react-feather'
import AxiosUtil, {ApiResponse} from 'utils/AxiosUtil'
import { getSigner, getRechargeContract, errorformat } from 'utils'
import { InfiniteScroll, Toast } from 'antd-mobile'
import type { ToastHandler } from 'antd-mobile/es/components/toast'
import { ethers } from 'ethers'
import { useTranslation } from 'react-i18next'
import Container from '../../components/Container'
import { useActiveWeb3React } from '../../hooks'
import { useCurrency, useToken } from '../../hooks/Tokens'
import { useDerivedBurnInfo } from '../../state/burn/hooks'
import CurrencyLogo from '../../components/CurrencyLogo'

const Body = styled.div`
  width: 100%;
  min-height: 400px;
  background: #FFFFFF;
  box-shadow: 0px 2px 0px 0px #E7E3EB;
  border-radius: 24px;
  border: 1px solid #E7E4EC;
  box-sizing: border-box;
  padding: 0 24px;
`
const Title = styled.div`
  display: flex;
  align-items: center;
  padding: 23px 0;
  border-bottom: 1px solid #E9E4F3;
`
const TitleText = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  color: #2B106B;
  font-family: Kanit, sans-serif;
`
const StyledArrowLeft = styled(ArrowLeft)`
  color: ${({ theme }) => theme.colors.text};
`
const LpBody = styled.div`
  padding: 20px 0 40px;
`
const LpTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #2B106B;
  line-height: 22px;
`
const CollectBtn = styled.div`
  line-height: 30px;
  background: #3EC8D4;
  border-radius: 15px;
  font-size: 14px;
  font-weight: bold;
  color: #FFFFFF;
  padding: 0 16px;
`
const CoinInfo = styled.div`
  margin: 16px 0;
  background: #EEEAF4;
  box-shadow: inset 1px 1px 4px 0px rgba(173,107,238,0.1), inset -1px -1px 4px 0px rgba(173,107,238,0.1);
  border-radius: 16px 16px 16px 16px;
  opacity: 1;
  border: 1px solid rgba(174,169,202,0.3);
  padding: 18px 16px 18px 12px;
`
const CoinInfoLine = styled.div`
  display: flex;
  align-items: center;
  img {
    display: block;
    width: 20px;
    height: 20px;
    margin-right: 6px;
  }
  .sp1 {
    flex: 1;
    min-width: 0;
    flex-shrink: 0;
    font-size: 16px;
    font-family: Kanit, sans-serif;
    font-weight: bold;
    color: #7B72A9;
    line-height: 22px;
  }
  .sp2 {
    flex: 1;
    min-width: 0;
    flex-shrink: 0;
    font-size: 16px;
    font-family: Kanit, sans-serif;
    font-weight: bold;
    color: #2B106B;
    line-height: 22px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: right;
  }
`
const CoinRecord = styled.div`
  margin-bottom: 10px;
  background: #EEEAF4;
  box-shadow: inset 1px 1px 4px 0px rgba(173,107,238,0.1), inset -1px -1px 4px 0px rgba(173,107,238,0.1);
  border-radius: 16px 16px 16px 16px;
  opacity: 1;
  border: 1px solid rgba(174,169,202,0.3);
  padding: 18px 16px 0 12px;
  overflow: hidden;
  font-family: PingFang SC-Medium, PingFang SC;
`
const CoinRecordItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 18px;
  .title {
    font-size: 14px;
    font-weight: 500;
    color: #7449D6;
    line-height: 20px;
  }
  .info {
    font-size: 14px;
    font-weight: 500;
    color: #2B106B;
    .text {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      &:nth-child(2) {
        margin-top: 4px;
      }
      img {
        display: block;
        width: 16px;
        height: 16px;
        margin-left: 7px;
      }
    }
  }
`
interface Bankdatas {
  address: string;
  name: string;
  yuenum: string;
}
interface Infodatas {
  nums: string;
  addtime: string;
}
// interface Tokendata {
//   token: string;
//   num: string;
// }

export default function LiquidityDetail({
  match: {
    params: { currencyIdA, currencyIdB },
  },
}: RouteComponentProps<{ currencyIdA: string; currencyIdB: string }>) {
  const handler = useRef<ToastHandler>()
  const [currencyA, currencyB] = [useCurrency(currencyIdA) ?? undefined, useCurrency(currencyIdB) ?? undefined]
  const { account, library } = useActiveWeb3React()
  const { pair } = useDerivedBurnInfo(currencyA ?? undefined, currencyB ?? undefined)
  const [address, setAddress] = useState<string>('')
  const [lpaddress, setLpaddress] = useState<string>('')
  const [bankdata, setBankdata] = useState<Array<Bankdatas>>([])
  const [hasMore, setHasMore] = useState(true)
  const [page, setPage] = useState('1')
  const [list, setList] = useState<Array<Infodatas>>([])
  const currencyTokenA = useToken(currencyIdA)
  const currencyTokenB = useToken(currencyIdB)
  const { t } = useTranslation()
  useEffect(() => {
    if (account) {
      setAddress(account)
    }
  }, [account])
  useEffect(() => {
    if (pair) {
      setLpaddress(pair?.liquidityToken?.address)
    }
  }, [pair])

  function getPrizeindex():Promise<any> {
    return new Promise((resolve, reject) => {
      AxiosUtil.request({
        url: '/api/v1/infoapi/prizeindex',
        method: 'POST',
        data: {
          address,
          lpaddress,
          page
        }
      }).then(res => {
        resolve(res)
      }).catch(e => {
        reject(e)
      })
    })
  }

  async function loadMore() {
    if (address && lpaddress) {
      const result = await getPrizeindex()
      if (result.data.bankdata.length) {
        setBankdata(result.data.bankdata)
      }

      if (result.data.infodata.datas.length) {
        const info = result.data.infodata
        if (Number(info.thispage) <= 1 && Number(page) === 1) {
          setList(info.datas)
        } else {
          setList(val => [...val,...info.datas])
        }
        setPage(info.thispage)
        setHasMore(Number(info.thispage) < Number(info.countpage))
      } else {
        setHasMore(false)
      }
    }
  }

  async function DoWithdraw() {
    handler.current = Toast.show({
      icon: 'loading',
      duration: 0,
      maskClickable: false,
    })
    try {
      if (typeof(library) === 'object' && typeof(account) === 'string') {
        const resnonce: ApiResponse<any> = await AxiosUtil.request({ url: '/api/v1/publicapi/getnonce', method: 'POST', data: { address }})
        const nonce: string = resnonce?.data?.nonce
        const signer = getSigner(library, address)
        const signature = await signer.signMessage(ethers.utils.arrayify(ethers.utils.toUtf8Bytes(nonce)))
        const result: ApiResponse<any> = await AxiosUtil.request({
          url: '/api/v1/publicapi/poolgetsave',
          method: 'POST',
          data: { address, lpaddress, signature }
        })
        // console.log(result);
        const orderid = result.data.orderid
        const [token0, token1] = [result.data.getdata[0], result.data.getdata[1]]
        // console.log(token0, token1);
        const token0Decimal = token0.token === currencyTokenA?.address ? currencyTokenA?.decimals : currencyTokenB?.decimals
        const token1Decimal = token1.token === currencyTokenA?.address ? currencyTokenA?.decimals : currencyTokenB?.decimals
        // console.log(token0Decimal, token1Decimal);
        const amount0 = new BigNumber(token0.num).multipliedBy(10 ** Number(token0Decimal)).toFixed()
        const amount1 = new BigNumber(token1.num).multipliedBy(10 ** Number(token1Decimal)).toFixed()
        const args = [
          orderid, amount0, amount1
        ]
        // console.log(args, library, account);
        const RechargeContract = getRechargeContract(library, account)
        // console.log(RechargeContract);
        const gasFree = await RechargeContract.gasFee()
        // console.log(gasFree.toString());
        const estimate = await RechargeContract.estimateGas.feeLp(...args, { value: gasFree })
        console.log(estimate.toString());
        const feelps = await RechargeContract.feeLp(...args, { value: gasFree })
        await feelps.wait()
        handler.current?.close()
        Toast.show({content: t('收集成功')})
        setPage('1')
        setHasMore(true)
        await loadMore()
          // let method: (...args: any) => Promise<TransactionResponse>
      } else {
        handler.current?.close()
      }
    }
    catch (e) {
      errorformat(e)
      handler.current?.close()
    }
    
  }

  const formatTime:(timestamp: string, type?: number) => string = (timestamp: string, type = 1) => {
    const date:Date = new Date(Number(`${timestamp}000`));
    const year:number = date.getFullYear();
    const month:number = date.getMonth() + 1;
    const day:number = date.getDate();
    const hour:number = date.getHours()
    const minute:number = date.getMinutes()
    const second:number = date.getSeconds()
  
    // return [year, month, day].map(formatNumber).join('-');
    if (type === 1) {
      return `${[year, month, day].join('-')} ${[hour, minute, second].join(':')}`
    }
    return [year, month, day].join('.')
  };

  // useEffect(() => {
  //   if (address && lpaddress) {
      
  //     AxiosUtil.request({
  //       url: '/api/v1/infoapi/prizeindex',
  //       method: 'POST',
  //       data: {
  //         address,
  //         lpaddress,
  //         page
  //       }
  //     }).then(res => {
  //       const result:any = res
  //       if (result.data.bankdata.length) {
  //         setBankdata(result.data.bankdata)
  //       }
        
  //       if (result.data.infodata.datas.length) {
  //         const info = result.data.infodata
  //         if (Number(info.thispage) <= 1 && Number(page) === 1) {
  //           setList(info.datas)
  //         } else {
  //           setList(val => [...val,...info.datas])
  //         }
  //         setPage(info.thispage)
          
  //         setHasMore(Number(info.thispage) < Number(info.countpage))
  //       }
  //     }).catch(e => {
  //       console.log(e);
  //     })
  //   }
  // }, [address, lpaddress, page, list])
  
  return (
    <Container>
      <Body>
        <Title>
          <HistoryLink to="/pool">
            <StyledArrowLeft style={{ marginRight: '10px' }} />
          </HistoryLink>
          <TitleText>
            <CurrencyLogo currency={currencyA} size="16px" style={{ marginRight: '4px' }} />
            <CurrencyLogo currency={currencyB} size="16px" style={{ marginRight: '8px' }} />
            {currencyA?.symbol}-{currencyB?.symbol}
          </TitleText>
        </Title>
        <LpBody>
          <LpTitle>
            <span>{t('LP分红费用')}</span>
            <CollectBtn onClick={DoWithdraw}>{t('收集')}</CollectBtn>
          </LpTitle>
          { bankdata.length ? 
          <CoinInfo>
            {bankdata.map((item, i) => {
              return (
                <CoinInfoLine key={item.address} style={ i === 1 ? { marginTop: '16px' } : {}}>
                  <CurrencyLogo currency={ i === 0 ? currencyA : currencyB } size="20px" style={{ marginRight: '6px' }} />
                  <span className='sp1'>{item.name}</span>
                  <span className='sp2'>{item.yuenum}</span>
                </CoinInfoLine>
              )
            })}
          </CoinInfo> : 
          <CoinInfo>
            <CoinInfoLine>
              <img src="./images/nologo.png" alt="" />
              <span className='sp1'>{currencyA?.symbol}</span>
              <span className='sp2'>0</span>
            </CoinInfoLine>
            <CoinInfoLine style={{ marginTop: '16px' }}>
              <img src="./images/nologo.png" alt="" />
              <span className='sp1'>{currencyB?.symbol}</span>
              <span className='sp2'>0</span>
            </CoinInfoLine>
          </CoinInfo>
          }
          {list.map(item => {
            return (
            <CoinRecord key={item.addtime}>
              <CoinRecordItem>
                <div className="title">{t('转移代币')}</div>
                <div className="info">
                  <div className="text">
                    <span>{item.nums.split(',')[0] || 0}</span>
                    <img src="./images/nologo.png" alt="" />
                  </div>
                  <div className="text">
                    <span>{item.nums.split(',')[1] || 0}</span>
                    <img src="./images/nologo.png" alt="" />
                  </div>
                </div>
              </CoinRecordItem>
              <CoinRecordItem>
                <div className="title">{t('操作')}</div>
                <div className="info">
                  <div className="text">
                    <span>{t('收集费用')}</span>
                  </div>
                </div>
              </CoinRecordItem>
              <CoinRecordItem>
                <div className="title">{t('时间戳')}</div>
                <div className="info">
                  <div className="text">
                    <span>{formatTime(item.addtime)}</span>
                    <img src="./images/net@2x.png" alt="" />
                  </div>
                </div>
              </CoinRecordItem>
            </CoinRecord>
            )
          })}
          <InfiniteScroll loadMore={loadMore} hasMore={hasMore} />
        </LpBody>
      </Body>
    </Container>
  )
}
