import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import AxiosUtil, {ApiResponse} from 'utils/AxiosUtil'
import { useHistory } from "react-router-dom";
import { getSigner } from 'utils'
import { InfiniteScroll, Toast } from 'antd-mobile'
import type { ToastHandler } from 'antd-mobile/es/components/toast'
import { useTranslation } from 'react-i18next'
import { ethers } from 'ethers'
import Container from '../../components/Container'
import { useActiveWeb3React } from '../../hooks'

const TeamTitle = styled.div`
  width: 100%;
  line-height: 44px;
  background: #FFFFFF;
  box-shadow: 0px -1px 0px 0px rgba(130,99,192,0.2);
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 18px;
  color: #111111;
  text-align: center;
`
const TeamBody = styled.div`
  width: 100%;
  .info {
    background: #FFFFFF;
    box-shadow: 0px 2px 0px 0px #E7E3EB;
    border-radius: 24px;
    border: 1px solid #E7E4EC;
    .title {
      font-family: PingFang SC, PingFang SC;
      font-weight: bold;
      font-size: 16px;
      color: #2B106B;
      line-height: 58px;
      padding-left: 24px;
      font-family: PingFang SC, PingFang SC;
      font-weight: bold;
      font-size: 16px;
      color: #2B106B;
      border-bottom: 1px solid #E9E4F3;
    }
    .detail {
      padding: 14px 24px 24px;
      display: flex;
      .item {
        width: 50%;
        flex-shrink: 0;
        .sp1 {
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          font-size: 12px;
          color: #2B106B;
          line-height: 17px;
        }
        .sp2 {
          font-family: PingFang SC, PingFang SC;
          font-weight: bold;
          font-size: 20px;
          color: #2B106B;
          line-height: 28px;
          margin-top: 4px;
          width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }
  .middle {
    font-family: PingFang SC, PingFang SC;
    font-weight: bold;
    font-size: 16px;
    color: #2B106B;
    line-height: 22px;
    margin: 24px 0 10px;
  }
  .list {
    .item {
      width: 100%;
      height: 164px;
      background: #FFFFFF;
      box-shadow: 0px 2px 0px 0px #E7E3EB;
      border-radius: 24px;
      border: 1px solid #E7E4EC;
      margin-bottom: 10px;
      padding: 18px 12px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .line {
        width: 100%;
        display: flex;
        justify-content: space-between;
        div {
          max-width: 50%;
          flex-shrink: 0;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          font-size: 14px;
          color: #7B72A9;
          line-height: 20px;
          &.sp2 {
            color: #2B106B;
            font-weight: bold;
          }
        }
      }
    }
  }
`

interface Infodatas {
  address: string;
  pernum: string;
  regtime: string;
  teamnum: string;
}
// interface Tokendata {
//   token: string;
//   num: string;
// }

export default function LiquidityDetail() {
  const handler = useRef<ToastHandler>()
  const { account, library } = useActiveWeb3React()
  const [address, setAddress] = useState<string>('')
  const [hasMore, setHasMore] = useState(true)
  const [page, setPage] = useState('1')
  const [list, setList] = useState<Array<Infodatas>>([])
  const [lvname, setLvname] = useState<string>('')
  const [pernum, setPernum] = useState<string>('')
  const [teamnum, setTeamnum] = useState<string>('')
  const history = useHistory()
  const { t } = useTranslation()
  useEffect(() => {
    if (account) {
      setAddress(account)
    }
  }, [account])

  function getPrizeindex(signature: string):Promise<any> {
    return new Promise((resolve, reject) => {
      AxiosUtil.request({
        url: '/api/v1/publicapi/teamList',
        method: 'POST',
        data: {
          address,
          page,
          signature
        }
      }).then(res => {
        resolve(res)
      }).catch(e => {
        reject(e)
      })
    })
  }

  async function loadMore() {
    if (typeof(library) === 'object' && address) {
      try {
        const resnonce: ApiResponse<any> = await AxiosUtil.request({ url: '/api/v1/publicapi/getnonce', method: 'POST', data: { address }})
        const nonce: string = resnonce?.data?.nonce
        const signer = getSigner(library, address)
        const signature = await signer.signMessage(ethers.utils.arrayify(ethers.utils.toUtf8Bytes(nonce)))
        const result = await getPrizeindex(signature)
        const info = result.data
        setLvname(info.lvname)
        setPernum(info.pernum)
        setTeamnum(info.teamnum)
        if (result.data.datas.length) {
          if (Number(info.thispage) <= 1 && Number(page) === 1) {
            setList(info.datas)
          } else {
            setList(val => [...val,...info.datas])
          }
          setPage(info.thispage)
          setHasMore(Number(info.thispage) < Number(info.countpage))
        } else {
          setHasMore(false)
        }
      }
      catch(e) {
        console.log(e);
        history.push('/subscribe')
      }
    }
  }

  const formatTime:(timestamp: string, type?: number) => string = (timestamp: string, type = 1) => {
    const date:Date = new Date(Number(`${timestamp}000`));
    const year:number = date.getFullYear();
    const month:number = date.getMonth() + 1;
    const day:number = date.getDate();
    const hour:number = date.getHours()
    const minute:number = date.getMinutes()
    const second:number = date.getSeconds()
  
    // return [year, month, day].map(formatNumber).join('-');
    if (type === 1) {
      return `${[year, month, day].join('-')} ${[hour, minute, second].join(':')}`
    }
    return [year, month, day].join('.')
  };

  
  return (
    <>
      <TeamTitle>{t('矿池信息')}</TeamTitle>
      <Container>
        <TeamBody>
          <div className="info">
            <div className="title">{lvname}</div>
            <div className="detail">
              <div className="item">
                <div className="sp1">{t('个人投资')}</div>
                <div className="sp2">{pernum}{t('算力')}</div>
              </div>
              <div className="item">
                <div className="sp1">{t('团队总投资')}</div>
                <div className="sp2">{teamnum}{t('算力')}</div>
              </div>
            </div>
          </div>
          <div className="middle">
            {t('矿池地址')}
          </div>
          <div className="list">
            {list.map(item => {
              return (
                <div className="item" key={item.address}>
                  <div className="line">
                    <div className="sp1">{t('钱包地址')}</div>
                    <div className="sp2">{item.address.substring(0, 7)}...{item.address.substring(item.address.length - 7, item.address.length)}</div>
                  </div>
                  <div className="line">
                    <div className="sp1">{t('会员投资')}</div>
                    <div className="sp2">{item.pernum} {t('算力')}</div>
                  </div>
                  <div className="line">
                    <div className="sp1">{t('会员市场业绩')}</div>
                    <div className="sp2">{item.teamnum} {t('算力')}</div>
                  </div>
                  <div className="line">
                    <div className="sp1">{t('绑定时间')}</div>
                    <div className="sp2">{formatTime(item.regtime)}</div>
                  </div>
                </div>
              )
            })}
            <InfiniteScroll loadMore={loadMore} hasMore={hasMore} />
          </div>
        </TeamBody>
      </Container>
    </>
  )
}
