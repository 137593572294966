import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import qs from 'qs'
import md5 from 'md5'
import { Toast } from 'antd-mobile'

const baseURL = process.env.REACT_APP_ENV_NAME === 'development' ? '/api' : process.env.REACT_APP_BASE_URL
const apiKey = process.env.REACT_APP_API_KEY
// 定义接口，用来规范返回数据格式
export interface ApiResponse<T> {
  status: number;
  info: string;
  data: T;
}
class AxiosUtil {
  private static instance: AxiosUtil;

  private axiosInstance: AxiosInstance;

  // 构造函数私有化，保证单例模式
  private constructor() {
    this.axiosInstance = axios.create({
      baseURL,
      timeout: 30000,
    });
    // 请求拦截器，可以在请求之前做一些处理，如添加token等
    this.axiosInstance.interceptors.request.use(
      (config) => {
        if (config.method === 'post') {
          if (!config.data) {
            config.data = {};
          }
          const storedLangCode = localStorage.getItem('pancakeSwapLanguage') || 'en'
          config.data.language = storedLangCode ;
          config.data.sign = md5(JSON.stringify(config.data) + apiKey);
          config.data = qs.stringify(config.data);
          return config;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    // 响应拦截器，对响应结果进行统一处理
    this.axiosInstance.interceptors.response.use(
      (response) => {
        const res = response.data as ApiResponse<any>;
        if (res.status === 0) {
          // 根据业务需求修改这里的提示方式，比如使用Message组件提示错误信息。
          Toast.show({content: res.info})
          return Promise.reject(response.data)
        }
        return response.data;
      },
      (error) => {
        console.error('网络异常', error);
        Toast.show({content: '网络异常'})
        return Promise.reject(error);
      }
    );
  }

  public static getInstance(): AxiosUtil {
    if (!this.instance) {
      this.instance = new AxiosUtil();
    }
    return this.instance;
  }

  public async request<T>(config: AxiosRequestConfig): Promise<T> {
    try {
      const response:unknown = await this.axiosInstance.request(config);
      return response as T;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}
export default AxiosUtil.getInstance();
