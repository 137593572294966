import React, { useContext, useEffect, useState } from 'react'
import { Menu as UikitMenu} from '@pancakeswap-libs/uikit'
import { useWeb3React } from '@web3-react/core'
import { allLanguages } from 'constants/localisation/languageCodes'
import { LanguageContext } from 'hooks/LanguageContext'
import useTheme from 'hooks/useTheme'
import { useTranslation } from 'react-i18next'
import useGetPriceData from 'hooks/useGetPriceData'
// import useGetLocalProfile from 'hooks/useGetLocalProfile'
import useAuth from 'hooks/useAuth'
import AxiosUtil from 'utils/AxiosUtil'
import links from './config'

const Menu: React.FC = (props) => {
  const { t } = useTranslation()
  const [lk, setLk] = useState<Array<any>>([])
  const { account } = useWeb3React()
  const { login, logout } = useAuth()
  const { selectedLanguage, setSelectedLanguage } = useContext(LanguageContext)
  const { isDark, toggleTheme } = useTheme()
  const priceData = useGetPriceData()
  let cakePriceUsd = 0;
  try {
    cakePriceUsd = priceData ? Number(priceData.data['0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82'].price ?? 0) : 0
  } catch (e) {
    // Ignore
  }
  // useEffect(() => {
  //   links.forEach(item => {
  //     item.label = t(item.label)
  //   })
  //   setLk(links)
    
  // }, [t, selectedLanguage, lk, setLk])
  // useEffect(() => {
  //   setLk(links)
  // }, [])
  useEffect(() => {
    AxiosUtil.request({
      url: '/api/v1/publicapi/openindex',
      method: 'POST',
      data: {
        type: '1007'
      }
    }).then(res => {
      const result:any = res
      if (result.data.state !== 1) {
        const newlinks = links.filter(item => item.icon !== 'InfoIcon')
        newlinks.forEach(item => {
          item.label = t(item.label)
        })
        setLk(newlinks)
      } else {
        links.forEach(item => {
          item.label = t(item.label)
        })
        setLk(links)
      }
    })
  }, [t])
  
  
  // const profile = useGetLocalProfile()

  return (
    <UikitMenu
      links={lk}
      account={account as string}
      login={login}
      logout={logout}
      isDark={isDark}
      toggleTheme={toggleTheme}
      currentLang={selectedLanguage?.code || ''}
      langs={allLanguages}
      setLang={setSelectedLanguage}
      cakePriceUsd={cakePriceUsd}
      {...props}
    />
  )
}

export default Menu
